.mainNum {
    padding-bottom: 30px;
    background-color: #3d1152;
    min-height: 100vh;
}

.chooseNum {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    padding: 0px;
    margin-top: 30px;
}

.mainNum>div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
    margin-top: 70px;
}

.mainNum>div>div {
    width: 20%;
    height: 300px;
    background-color: black;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
}

.mainNum>div>div:hover {
    transform: translateY(-10px);
}

.mainNum>div>div>h2 {
    font-size: 100px;
    color: white;
}

.mainNum>div>div>p {
    font-weight: 600;
    color: white;
}

.homeBtnNum {
    font-size: 20px;
    color: white;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: -45px;
}

.theSelectNumDiv {
    width: 35%;
    height: 230px;
    position: fixed;
    border-radius: 12px;
    box-shadow: 1px 1px 6px black;
    background-color: #fdfdfe;
    top: 30%;
    left: 32.5%;
    padding: 30px 20px;
    display: none;
    z-index: 2;
}

.theSelectNumDiv>button {
    margin-left: 5%;
    margin-top: 10px;
    width: 90%;
    height: 50px;
    border: 2px solid #3d1152;
    outline: none;
    border-radius: 24px;
    font-weight: 600;
}

.theSelectNumDiv>button:hover {
    background-color: #3d1152;
    color: white;
}

.theSelectNumDiv>p {
    text-align: center;
}

.theNumShow {
    display: block;
}

@media screen and (max-width: 800px) {
    .mainNum>div>div {
        width: 90%;
    }

    .chooseNum {
        font-size: 14px;
    }

    .homeBtnNum {
        font-size: 14px;
    }
}