.mainGame {
    padding: 30px;
    background-color: #3d1152;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.mainGame>div {
    width: 100%;
    min-height: 80vh;
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    position: relative;
}

.mainGame>div>h1 {
    text-align: left;
    margin-left: 40px;
    color: #3d1152;
    text-decoration: underline;
}

.mainGame>div>img {
    width: 200px;
    position: absolute;
    right: 60px;
    top: 50px;
}

.mainGame>div>p {
    font-size: 30px;
    text-align: left;
    margin-left: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-transform: capitalize;
    color: #3d1152;
    font-weight: 600;
    width: 80%;
}

.theAnswer {
    margin-left: 50px;
    margin-top: 50px;
}

.mainGame>div>div>p {
    font-size: 24px;
    font-weight: 600;
    color: #3d1152;
    margin-top: 20px;
    display: flex;
    gap: 10px;
    align-items: baseline;
}

.answer {
    min-width: 300px;
    max-width: auto;
    height: 50px;
    background-color: #3d1152;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 200;
    padding: 5px 15px;
    border-radius: 6px;
    cursor: pointer;
    text-transform: capitalize;
}

.answer:hover {
    background-color: white;
    color: #3d1152;
    border: 1px solid #3d1152;
    font-weight: 600;
}

.learnButton {
    width: 100%;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 20px;
    margin-left: 0px;
}

.learnMore {
    width: 45%;
    height: 50px;
    font-size: 20px;
    border: 1px solid #3d1152;
    outline: none;
    color: #3d1152;
    background-color: white;
    font-weight: 600;
    border-radius: 8px;
    visibility: hidden;
}

.nextQuest {
    width: 45%;
    height: 50px;
    font-size: 20px;
    border: none;
    outline: none;
    font-weight: 600;
    border-radius: 8px;
    background-color: #3d1152;
    color: white;
}

#quizTimer {
    font-size: 30px;
}

.learnModal {
    width: 40%;
    height: auto;
    max-height: 600px;
    position: fixed;
    background-color: rgb(225, 221, 221);
    top: 60px;
    left: 27.5%;
    border-radius: 12px;
    padding: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    display: none;
}

.learnModal::-webkit-scrollbar {
    display: none;
}

.learnModal>img {
    margin-left: 96%;
    width: 20px;
    cursor: pointer;
}

.learnModal>pre {
    margin-top: 20px;
    line-height: 25px;
}

.quizScoreModal {
    width: 30%;
    height: 600px;
    position: fixed;
    background-color: rgb(225, 221, 221);
    top: 60px;
    left: 32.5%;
    border-radius: 12px;
    padding: 20px;
    display: none;
}

.quizScoreModal>img {
    margin-left: 96%;
    width: 20px;
    cursor: pointer;
}

.quizScoreModal>div {
    margin-top: 20px;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #3d1152;
}

.quizScoreModal>div>p {
    font-size: 20px;
    font-weight: 600;
    color: #3d1152;
    text-transform: capitalize;
}

.quizScoreModal>div>button {
    width: 35%;
    height: 50px;
    margin-top: 20px;
    border: 2px solid #3d1152;
    outline: none;
    border-radius: 24px;
    font-size: 18px;
    color: #3d1152;
    font-weight: 600;
}

.quizScoreModal>div>button:hover {
    background-color: #3d1152;
    color: white;
}

.showLearn {
    display: block;
}

.correct {
    background-color: green;
}

.correct:hover {
    background-color: green;
    color: white;
    border: none;
    font-weight: 400;
}

.wrong {
    background-color: red;
}

.wrong:hover {
    background-color: red;
    color: white;
    border: none;
    font-weight: 400;
}

.homeBtnGame {
    font-size: 20px;
    font-weight: bold;
    color: #3d1152;
    cursor: pointer;
    position: absolute;
    right: 80px;
    top: 50px;
    z-index: 2;
}

.howToPlayTrivial {
    position: fixed;
    width: 40%;
    top: 10%;
    left: 30%;
    height: auto;
    background-color: white;
    padding: 20px;
    padding-bottom: 40px;
    border-radius: 16px;
    box-shadow: -0.4px 0px 3px #000000;
    display: none;
}

.howToPlayTrivial>h1 {
    font-weight: 900;
    font-size: 30px;
}

.howToPlayTrivial>h4 {
    font-size: 20px;
}

.howToPlayHangImg {
    margin-left: 96%;
    margin-top: 10px;
    cursor: pointer;
}

.gameHowImg {
    margin: 0 auto;
}

.showHowToPlay {
    display: block;
}

@media screen and (max-width: 800px) {
    .mainGame {
        padding: 10px;
    }

    .learnMore,
    .nextQuest {
        width: 100%;
    }

    .mainGame>div>img {
        display: none;
    }

    .homeBtnGame {
        right: 25px;
        top: 25px;
    }

    .mainGame>div>h1,
    .mainGame>div>p,
    .theAnswer {
        margin-left: 0px;
    }

    .mainGame>div>p {
        font-size: 16px;
    }

    .answer {
        font-size: 14px;
    }

    .learnModal {
        width: 90%;
        left: 5%;
    }

    .quizScoreModal {
        width: 90%;
        left: 5%;
    }

    .quizScoreModal>div>button {
        width: 50%;
    }
}