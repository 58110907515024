.mainDeckDiv {
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    gap: 30px;
    justify-content: flex-start;
    align-items: center;
}

.mainDeckDiv>div {
    width: 23%;
    height: 500px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 600;
    color: white;
    text-align: center;
    cursor: pointer;
    flex-direction: column;
}

.alphaDiv {
    background-image: linear-gradient(120deg, red 0%, blue 100%);
}

.mainDeckDiv>div>img {
    width: 200px;
    height: 200px;
}

@media (max-width: 700px) {
    .mainDeckDiv>div {
        width: 100%;
    }
}