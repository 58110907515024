.bg-purple {
  background: #3d1152 !important;
}

.fab-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.fab-container:hover {
  height: 100%;
}

.fab-container:hover .sub-button:nth-child(2) {
  transform: translateY(-80px);
}

.fab-container:hover .sub-button:nth-child(3) {
  transform: translateY(-140px);
}

.fab-container:hover .sub-button:nth-child(4) {
  transform: translateY(-200px);
}

.fab-container:hover .sub-button:nth-child(5) {
  transform: translateY(-260px);
}

.fab-container:hover .sub-button:nth-child(6) {
  transform: translateY(-320px);
}

.fab-container .fab {
  position: relative;
  height: 70px;
  width: 70px;
  background-color: #4ba2ff;
  border-radius: 50%;
  z-index: 2;
}

.fab-container .fab::before {
  content: " ";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 35px;
  width: 35px;
  background-color: inherit;
  border-radius: 0 0 0px 0;
  z-index: -1;
}

.fab-container .fab .fab-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.fab-container .fab .fab-content .material-icons {
  color: white;
  font-size: 48px;
}

.fab-container .sub-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
  background-color: #4ba2ff;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.fab-container .sub-button:hover {
  cursor: pointer;
}

.fab-container .sub-button .material-icons {
  color: white;
  padding-top: 6px;
}

.doom {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

.video-container {
  z-index: 1 !important;
  padding: 0;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}

#videowrapper {
  position: relative;
  overflow: hidden;
}

#fullScreenDiv {
  min-height: 100%;
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  background-color: gray;
  position: relative;
}

#video {
  width: 100vw;
  height: auto;
  margin: auto;
  display: block;
}

#videoMessage {
  width: 100%;
  height: 100%;
  position: absolute;
}

.card-video {
  width: 100% !important;
}