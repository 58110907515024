.loginMainDiv {
    background-color: #3d1152;
    width: 100vw;
    min-height: 100vh;
    padding: 1rem;
}

.loginDiv {
    width: 40%;
    height: auto;
    background-color: white;
    margin: 0 auto;
    padding: 20px 20px 10px 20px;
    border-radius: 8px;
}

.loginDiv>h1 {
    font-size: 30px;
    font-size: 600;
    text-align: center;
    font-family: 'Switzer';
    font-style: normal;
    color: #374151;
}

.loginDiv>form {
    margin-top: 25px;
}

.loginDiv>form>div {
    width: 100%;
    margin-top: 15px;
}

.loginDiv>form>label {
    font-family: 'Switzer';
    font-style: normal;
}

.loginDiv h6 {
    color: red;
    text-align: center;
}

.loginDiv input {
    width: 100%;
    height: 50px;
    background: #F3F4F6;
    padding: 20px;
    font-family: 'Switzer';
    font-style: normal;
    border: none;
    outline: none;
    margin-top: 5px;
}

.loginDiv input:focus {
    background-color: white;
    border: 1px solid #3d1152;
}

.loginDiv button {
    margin-top: 20px;
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    background: #3d1152;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Switzer';
    font-style: normal;
    color: white;
    border-radius: 8px;
    cursor: pointer;
}

.loginDiv p {
    text-align: center;
    margin-top: 10px;
    font-family: 'Switzer';
    font-style: normal;
    color: #6B7280;
}

.loginDiv p>span,
.forgotPass {
    color: #2E7DD7;
    cursor: pointer;
}

.goHome {
    display: none;
}

.lockImage {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.loginDiv .tribeMain {
    border: 1px solid #3d1152;
    width: 100%;
    height: 50px;
    border-radius: 8px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    font-size: 20px;
    cursor: pointer;
    font-family: 'Switzer';
    font-style: normal;
}

.homeBtnLog {
    position: absolute;
    top: 20px;
    left: 20px;
}

@media (max-width: 700px) {
    .homeBtnLog {
        display: none;
    }

    .goHome {
        display: block;
    }
}


@media screen and (max-width: 800px) {
    .loginDiv {
        width: 95%;
    }
}

.show {
    display: block;
}