.swordDiv {
    width: 100%;
    font-family: 'Switzer';
    font-style: normal;
    text-transform: capitalize;
    padding-bottom: 20px;
}

.swordDiv>h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #3d1152;
    font-style: italic;
    margin-top: 20px;
}

.swordSearch {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    padding: 0px 100px;
}

.swordSearch>input {
    width: 80%;
    border-radius: 12px;
    border: 1px solid #3d1152;
    font-family: 'Switzer';
    padding-left: 10px;
    height: 40px;
    color: gray;
    outline: none;
}

.swordSearch>p {
    width: 15%;
    border-radius: 12px;
    background-color: #3d1152;
    color: white;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.swordSearch>p:hover {
    background-color: white;
    color: #3d1152;
    border: #3d1152 1px solid;
    font-weight: 600;
}

.swordMain {
    padding-top: 30px;
}

.swordMain>div {
    height: auto;
    width: 87%;
    margin: 0 auto;
    border-radius: 24px;
    box-shadow: 2px 2px 4px #3d1152;
    margin-top: 30px;
    cursor: pointer;
    padding: 25px;
    overflow: hidden;
    position: relative;
    z-index: 100;
}

.swordBtn {
    position: absolute;
    right: 20px;
    z-index: 2;
    display: flex;
}

.verses {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
}

.verses>p {
    border: #3d1152 1px solid;
    padding: 6px 10px;
    border-radius: 8px;
    color: #3d1152;
}

.verses>p:hover {
    background-color: #3d1152;
    color: white;
}

.theNote {
    line-height: 1.5rem;
    max-height: 3rem;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
}

.chapter {
    width: 35%;
    height: auto;
    max-height: 550px;
    position: fixed;
    border-radius: 12px;
    box-shadow: 1px 1px 6px black;
    background-color: #fdfdff;
    top: 40px;
    left: 32.5%;
    padding: 30px 20px;
    display: none;
    z-index: 150;
    overflow-y: scroll;
    overflow-x: hidden;
}

.chapter>div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.chapter>div>h5 {
    font-size: 20px;
    font-weight: 600;
}

.chapter>div>img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.chapter>p {
    margin-top: 20px;
    line-height: 30px;
    font-weight: 500;
}

.innerSword {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.swordBtn img {
    width: 20px;
}

.innerSword>h4 {
    width: 70%;
}

.delDiv {
    width: 35%;
    height: 230px;
    position: fixed;
    border-radius: 12px;
    box-shadow: 1px 1px 6px black;
    background-color: #fdfdfe;
    top: 30%;
    left: 32.5%;
    padding: 30px 20px;
    display: none;
    z-index: 200;
}

.firstDel {
    display: flex;
    gap: 20px;
    align-items: center;
}

.firstDel>img {
    width: 120px;
}

.delDiv h5 {
    font-weight: 600;
    font-size: 18px;
    font-family: 'Switzer';
}

.secondDel {
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
}

.delCan {
    background: #FFFFFF;
    border: 1px solid #dfe1e4;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 44px;
    font-weight: 600;
    cursor: pointer;
}

.delDel {
    background: #F43F5E;
    border: 1px solid #F43F5E;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 44px;
    font-weight: 600;
    color: white;
    cursor: pointer;
}

.empty {
    text-align: center;
    margin-top: 20px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.homeBtnSword {
    font-size: 20px;
    color: #3d1152;
    cursor: pointer;
    position: fixed;
    z-index: 2;
    left: 20px;
    font-weight: bold;
}

.swordSub {
    width: 35%;
    height: 230px;
    position: fixed;
    border-radius: 12px;
    box-shadow: 1px 1px 6px black;
    background-color: #fdfdfe;
    top: 30%;
    left: 32.5%;
    padding: 30px 20px;
    display: none;
    z-index: 200;
}

.swordSub>button {
    margin-left: 5%;
    margin-top: 10px;
    width: 90%;
    height: 50px;
    border: 2px solid #3d1152;
    outline: none;
    border-radius: 24px;
    font-weight: 600;
}

.swordSub>button:hover {
    background-color: #3d1152;
    color: white;
}

.swordSub>p {
    text-align: center;
}

.showBlock {
    display: block;
}

.howToPlayShield {
    position: fixed;
    width: 40%;
    top: 10%;
    left: 30%;
    height: auto;
    background-color: white;
    padding: 20px;
    padding-bottom: 40px;
    border-radius: 16px;
    box-shadow: -0.4px 0px 3px #000000;
    display: none;
    z-index: 300;
}

.howToPlayShield>h1 {
    font-weight: 900;
    font-size: 30px;
}

.howToPlayShield>h4 {
    font-size: 20px;
}

.howToPlayHangImg {
    margin-left: 96%;
    margin-top: 10px;
    cursor: pointer;
}

.swordHowImg {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    margin-top: 20px;
}

.showHowToPlay {
    display: block;
}

@media screen and (max-width: 800px) {
    .swordSub {
        width: 90%;
        left: 5%;
        height: 260px;
    }

    .swordDiv>h1 {
        font-size: 22px;
    }

    .swordSearch {
        padding: 0px 30px;
    }

    .swordSearch>input {
        width: 70%;
    }

    .swordSearch>p {
        width: 25%;
    }

    .innerSword>h4 {
        font-size: 18px;
    }

    .swordBtn {
        flex-shrink: 0;
    }

    .chapter {
        width: 90%;
        left: 5%;
        top: 100px;
        max-height: 700px;
    }

    .delDiv {
        width: 90%;
        left: 5%;
        height: 260px;
    }

    #edit,
    #delete {
        display: none;
    }

    .empty {
        font-size: 20px;
    }

    .homeBtnSword {
        font-size: 16px;
    }
}