.questMain {
    padding: 20px;
}

.questMain>h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #3d1152;
    font-style: italic;
}

.questForm {
    padding: 50px 100px;
}

.questForm label {
    font-size: 18px;
    margin-bottom: 5px;
}

.questForm>input {
    width: 100%;
    height: 50px;
    border: 1px solid #b9bdc5;
    border-radius: 2px;
    outline: none;
    padding-left: 10px;
}

.questForm>h6 {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
}

.learnMoreArea {
    width: 100%;
    height: 170px;
    border: 1px solid #b9bdc5;
    border-radius: 2px;
    outline: none;
    padding: 10px;
    resize: none;
}

.createQuest {
    margin-top: 20px;
    width: 164px;
    height: 46px;
    background: green;
    border-radius: 4px;
    border: none;
    outline: none;
    color: white;
}

.addQuestBtn>button:last-child {
    margin-top: 20px;
    width: 164px;
    height: 46px;
    background: red;
    border-radius: 4px;
    border: none;
    outline: none;
    color: white;
    margin-left: 10px;
}

@media screen and (max-width: 800px) {
    .questForm {
        padding: 30px 20px;
    }
}