a:link { 
    text-decoration: none !important; 
}

p {
    margin-bottom: 0px !important;
}

.bg-gold-deep {
    background-color: #FFBA15 ¡important;
    background: #FFBA15 ¡important;
}