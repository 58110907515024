.btn {
    width: 100%;
    border: 3px solid white;
    background: none;
    aspect-ratio: 1 / 1;
    font-size: 2.5rem;
    text-transform: uppercase;
    padding: .5rem;
    font-weight: bold;
    cursor: pointer;
    color: white;
}

.btnActive {
    color: white;
    background-color: hsl(200, 100%, 75%);
}

.btnInactive {
    color: white;
}

.btn:hover:not(:disabled),
.btn:focus:not(:disabled) {
    background-color: red;
    color: white;
}

.btn:disabled {
    color: white;
    background-color: red;
}