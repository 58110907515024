.privacyHead {
    text-align: center;
    padding: 20px;
    margin-top: 20px;
    border-bottom: 1px solid purple;
    border-top: 1px solid purple;
}

.privacyHead>h1 {
    font-size: 30px;
    font-weight: 600;
}

.privacyText {
    padding: 30px;
    border-bottom: 1px solid purple;
}

.privacyText>p {
    font-size: 20px;
    font-weight: 600;
}

.privacyText>li {
    list-style-type: none;
    line-height: 50px;
    font-size: 18px;
    font-weight: 500;
    padding-left: 20px;
}