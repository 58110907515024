.normal,
.hard,
.veryHard,
.impossible {
    transition: ease-out 0.3s;
    background-color: white;
    width: 380px;
    height: 300px;
    border: none;
    outline: none;
    border-radius: 12px;
    font-size: 40px;
    font-weight: 600;
    font-family: 'Switzer';
    font-style: normal;
}

.firstDiv div {
    position: absolute;
    top: 30px;
    right: 60px;
    cursor: pointer;
}

.selectShow {
    display: block;
}

@media (max-width: 700px) {
    .firstDiv div {
        right: 30px;
        top: 20px;
    }
}

.normal {
    box-shadow: inset 0 0 0 0 green;
}

.normal:hover {
    box-shadow: inset 380px 0 0 0 green;
    color: white;
}

.hard {
    box-shadow: inset 0 0 0 0 yellow;
}

.hard:hover {
    box-shadow: inset 380px 0 0 0 yellow;
}

.veryHard {
    box-shadow: inset 0 0 0 0 red;
}

.veryHard:hover {
    box-shadow: inset 380px 0 0 0 red;
    color: white;
}

.impossible {
    box-shadow: inset 0 0 0 0 black;
}

.impossible:hover {
    box-shadow: inset 380px 0 0 0 black;
    color: white;
}