.selectMain {
    background-color: #3d1152;
    width: 100vw;
    min-height: 100vh;
    padding: 30px;
}

.firstDiv {
    display: flex;
    gap: 20px;
}

.firstDiv p {
    color: white;
    cursor: pointer;
}

.firstDiv>p>span {
    margin-left: 20px;
    cursor: pointer;
}

.innerMain {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 100px;
    row-gap: 40px;
    margin-top: 3%;
}

.innerMain>div {
    width: 22%;
    height: 300px;
    background-color: black;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* box-shadow: 2px 2px 4px rgba(242, 239, 239, 0.958); */
    cursor: pointer;
}

.innerMain>div:hover {
    transform: translateY(-10px);
}

.innerMain>div>p {
    font-size: 22px;
    font-weight: 600;
    font-family: 'Switzer';
    font-style: normal;
    text-transform: capitalize;
    color: white;
    text-align: center;
}

.innerMain>div>img {
    width: 70%;
    height: 220px;
}

.selectSub {
    width: 35%;
    height: 230px;
    position: fixed;
    border-radius: 12px;
    box-shadow: 1px 1px 6px black;
    background-color: #fdfdfe;
    top: 30%;
    left: 32.5%;
    padding: 30px 20px;
    display: none;
    z-index: 200;
}

.selectSub>button {
    margin-left: 5%;
    margin-top: 10px;
    width: 90%;
    height: 50px;
    border: 2px solid #3d1152;
    outline: none;
    border-radius: 24px;
    font-weight: 600;
}

.selectSub>button:hover {
    background-color: #3d1152;
    color: white;
}

.selectSub>p,
.aboutSub>p {
    text-align: center;
}

.aboutSub {
    width: 35%;
    height: auto;
    position: fixed;
    border-radius: 12px;
    box-shadow: 1px 1px 6px black;
    background-color: #fdfdfe;
    top: 11%;
    left: 32.5%;
    padding: 30px 20px;
    display: none;
    z-index: 200;
    text-align: center;
}

.aboutSub>p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    margin: 0 auto;
}

.aboutSub>h6>p {
    font-weight: 600;
}

.aboutSub>p>img {
    width: 70px;
    height: 70px;
}

.aboutSub>p>span {
    text-align: left;
    width: 100px;
}

.selectShow {
    display: block;
}

@media screen and (max-width: 800px) {
    .innerMain>div {
        width: 100%;
    }

    .innerMain>div>p {
        font-size: 20px;
    }

    .aboutSub {
        width: 90%;
        left: 5%;
    }
}