.payDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    height: 70vh;
    padding: 20px;
}

.payDiv>p {
    font-weight: 600;
    text-align: center;
}

.payDiv>form {
    width: 50%;
    border: 1px solid purple;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.payDiv>form>button {
    width: 100%;
    height: 50px;
    background-color: purple;
    color: white;
    border: none;
    outline: none;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 12px;
}

.payDiv>form>button:hover {
    background-color: green;
}

.payDiv select {
    margin-bottom: 25px;
    height: 35px;
    padding: 5px;
    border: 1px solid purple;
    border-radius: 6px;
    outline: none;
}

.successDiv {
    width: 30%;
    height: 300px;
    border: 1px solid purple;
    position: absolute;
    top: 20%;
    left: 35%;
    border-radius: 24px;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: beige;
    text-align: center;
    padding: 20px;
}

.successDiv .tryButton {
    background-color: green;
    width: 100%;
    height: 40px;
    margin-top: 40px;
    color: white;
    font-weight: 600;
    border-radius: 12px;
}

.successDiv .cancelButton {
    background-color: red;
    width: 100%;
    height: 40px;
    margin-top: 15px;
    color: white;
    font-weight: 600;
    border-radius: 12px;
}

.successDiv p {
    font-weight: 600;
}

.show {
    display: flex;
}

@media (max-width: 700px) {
    .successDiv {
        width: 90%;
        left: 5%;
    }

    .payDiv>form {
        width: 100%;
        border-radius: 24px;
    }
}