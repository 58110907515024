.addMain {
    padding: 20px;
}

.addMain>h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: #3d1152;
    font-style: italic;
}

.addForm {
    padding: 50px 100px;
}

.addForm label {
    font-size: 18px;
    margin-bottom: 5px;
}

.topic {
    width: 100%;
    height: 50px;
    border: 1px solid #b9bdc5;
    border-radius: 2px;
    outline: none;
    padding-left: 10px;
}

.addForm>h6 {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 600;
}

.verseContent {
    width: 100%;
    height: 100px;
    border: 1px solid #b9bdc5;
    border-radius: 2px;
    outline: none;
    padding: 10px;
    resize: none;
}

.another {
    margin-top: 20px;
    width: 164px;
    height: 46px;
    background: #2E7DD7;
    border-radius: 4px;
    border: none;
    outline: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.note {
    width: 100%;
    height: 170px;
    border: 1px solid #b9bdc5;
    border-radius: 2px;
    outline: none;
    padding: 10px;
    resize: none;
}

.createStudy {
    width: 164px;
    height: 46px;
    background: green;
    border-radius: 4px;
    border: none;
    outline: none;
    color: white;
}

.cancelBtn {
    width: 164px;
    height: 46px;
    background: tomato;
    border-radius: 4px;
    border: none;
    outline: none;
    color: white;
}

.theCancelDiv {
    display: flex;
    margin-top: 20px;
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
}

@media screen and (max-width: 800px) {
    .addForm {
        padding: 30px 20px;
    }

    .createStudy,
    .cancelBtn {
        width: 100%;
    }

}