*,
*::after,
*::before {
    box-sizing: border-box;
    font-family: Arial;
}

.wordleMain {
    background-color: #3d1152;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    padding: 1em;
    font-size: clamp(.5rem, 2.5vmin, 1.5rem);
}

.boxWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
}

.keyboard {
    display: grid;
    grid-template-columns: repeat(20, minmax(auto, 1.25em));
    grid-auto-rows: 3em;
    gap: .25em;
    justify-content: flex-end;
    /* margin-top: 100px; */
}

.key {
    font-size: inherit;
    grid-column: span 2;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: hsl(var(--hue, 200),
            var(--saturation, 1%),
            calc(var(--lightness-offset, 0%) + var(--lightness, 51%)));
    color: white;
    fill: white;
    text-transform: uppercase;
    border-radius: .25em;
    cursor: pointer;
    user-select: none;
}

.key.large {
    grid-column: span 3;
}

.key>svg {
    width: 1.75em;
    height: 1.75em;
}

.key:hover,
.key:focus {
    --lightness-offset: 10%;
}

.key.wrong {
    --lightness: 23%;
}

.key.wrong-location {
    --hue: 49;
    --saturation: 51%;
    --lightness: 47%;
}

.key.correct {
    --hue: 115;
    --saturation: 29%;
    --lightness: 43%;
}

.guess-grid {
    margin-top: 5rem;
    display: grid;
    justify-content: flex-start;
    align-content: center;
    flex-grow: 1;
    width: 100% !important;
    max-height: 70% !important;
    grid-template-columns: repeat(5, 2.7em);
    grid-template-rows: repeat(6, 2.7em);
    gap: .25em;
    /* overflow-y: scroll; */
    margin-bottom: 1em;
}

.tile {
    font-size: 2em;
    color: white;
    border: .05em solid wheat;
    text-transform: uppercase;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    transition: transform 250ms linear;
    width: 45px;
    height: 45px;
}

.tile[data-state="active"] {
    border-color: hsl(200, 1%, 34%);
}

.tile[data-state="wrong"] {
    border: none;
    background-color: hsl(240, 2%, 23%);
}

.tile[data-state="wrong-location"] {
    border: none;
    background-color: hsl(49, 51%, 47%);
}

.tile[data-state="correct"] {
    border: none;
    background-color: hsl(115, 29%, 43%);
}

.tile.shake {
    animation: shake 250ms ease-in-out;
}

.tile.dance {
    animation: dance 500ms ease-in-out;
}

.tile.flip {
    transform: rotateX(90deg);
}

@keyframes shake {
    10% {
        transform: translateX(-5%);
    }

    30% {
        transform: translateX(5%);
    }

    50% {
        transform: translateX(-7.5%);
    }

    70% {
        transform: translateX(7.5%);
    }

    90% {
        transform: translateX(-5%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes dance {
    20% {
        transform: translateY(-50%);
    }

    40% {
        transform: translateY(5%);
    }

    60% {
        transform: translateY(-25%);
    }

    80% {
        transform: translateY(2.5%);
    }

    90% {
        transform: translateY(-5%);
    }

    100% {
        transform: translateY(0);
    }
}

.alert-container {
    position: fixed;
    top: 10vh;
    left: 50vw;
    transform: translateX(-50%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alert {
    pointer-events: none;
    background-color: hsl(204, 7%, 85%);
    padding: .75em;
    border-radius: .25em;
    opacity: 1;
    transition: opacity 500ms ease-in-out;
    margin-bottom: .5em;
}

.alert:last-child {
    margin-bottom: 0;
}

.alert.hide {
    opacity: 0;
}

.wordLabel {
    color: white;
}

.noOfWord {
    width: 160px;
    height: 30px;
}

.hint {
    position: absolute;
    right: 19%;
    background-color: white;
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 90px;
    border-radius: 12px;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
    color: #3d1152;
    cursor: pointer;
    box-shadow: 0 0 4px 4px white;
}

.myTimer {
    position: absolute;
    display: flex;
    right: 11%;
    top: 83px;
}

.myTimer p {
    color: white;
    font-size: 30px;
}

.theHint {
    position: absolute;
    left: 41.5%;
    margin: 20px 0px;
    height: 25px;
    color: white;
    display: none;
    text-transform: capitalize;
}


.leader {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 18px;
    right: 15px;
    cursor: pointer;
    filter: invert(1);
    z-index: 40;
    box-shadow: 0 0 6px 6px black;
}

.startBtn {
    width: 20%;
    height: 70px;
    position: fixed;
    top: 50%;
    left: 40%;
    cursor: pointer;
    border-radius: 12px;
    background-color: black;
    color: white;
    font-weight: 600;
}

.startBtnLandscape {
    width: 30%;
    height: 10%;
    position: fixed;
    top: 50%;
    left: 42.5%;
    cursor: pointer;
}

.bigDiv {
    width: 100%;
    height: 90vh;
    background-color: aliceblue;
    position: fixed;
    top: 10%;
    left: 0px;
    opacity: 0;
}


.fab-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    user-select: none;
    position: absolute;
    bottom: 30px;
    right: 10px;
}

.fab-container:hover {
    height: 100%;
}

.fab-container:hover .sub-button:nth-child(2) {
    transform: translateY(-80px);
}

.fab-container:hover .sub-button:nth-child(3) {
    transform: translateY(-140px);
}

.fab-container:hover .sub-button:nth-child(4) {
    transform: translateY(-200px);
}

.fab-container:hover .sub-button:nth-child(5) {
    transform: translateY(-260px);
}

.fab-container:hover .sub-button:nth-child(6) {
    transform: translateY(-320px);
}

.fab-container .fab {
    position: relative;
    height: 70px;
    width: 70px;
    background-color: #4ba2ff;
    border-radius: 50%;
    z-index: 2;
}

.fab-container .fab::before {
    content: " ";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 35px;
    width: 35px;
    background-color: inherit;
    border-radius: 0 0 0px 0;
    z-index: -1;
}

.fab-container .fab .fab-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.fab-container .fab .fab-content .material-icons {
    color: white;
    font-size: 48px;
}

.fab-container .sub-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    right: 10px;
    height: 50px;
    width: 50px;
    background-color: #4ba2ff;
    border-radius: 50%;
    transition: all 0.3s ease;
}

.fab-container .sub-button:hover {
    cursor: pointer;
}

.fab-container .sub-button .material-icons {
    color: white;
    padding-top: 6px;
}

.btn-keyboard {
    font-size: inherit;
    border: none;
    padding: 0;
    align-items: center;
    background-color: red !important;
    /* color: white;
    fill: white; */
    text-transform: uppercase;
    border-radius: .25em;
    cursor: pointer;
    user-select: none;
}

.h-100-vh {
    min-height: 100vh !important;
    /* height: 100vh !important; */
}

.wordleHome {
    display: flex;
    gap: 30px;
    align-items: baseline;
    padding-top: 10px;
    position: relative;
    width: 300px;
}

.wordleHome>p {
    color: white;
    font-size: 30px;
    text-transform: capitalize;
    margin-left: 60px;
}

.wordleHome>div {
    cursor: pointer;
    position: absolute;
    z-index: 3;
    top: 13px;
}

.howToPlay {
    position: absolute;
    width: 40%;
    top: 10%;
    left: 30%;
    height: auto;
    background-color: white;
    padding: 20px;
    padding-bottom: 40px;
    border-radius: 16px;
    display: none;
}

.howToPlay>h1 {
    font-weight: 900;
    font-size: 30px;
}

.howToPlay>h4 {
    font-size: 20px;
}

.howToPlay li {
    list-style-type: disc;
    font-size: 14px;
}

.howToPlay h6 {
    font-weight: 600;
}

.example1>div,
.example2>div,
.example3>div {
    display: flex;
    gap: 5px;
}

.example1>div>div,
.example2>div>div,
.example3>div>div {
    width: 30px;
    height: 30px;
    border: 2px solid grey;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
}

.howToPlay span {
    font-weight: 900;
}

.howToPlay img {
    margin-left: 96%;
    margin-top: 10px;
    cursor: pointer;
}

.example1>div>div:first-child {
    background-color: green;
    color: white;
    border: none;
}

.example2>div>div:nth-child(2) {
    background-color: goldenrod;
    color: white;
    border: none;
}

.example3>div>div:nth-child(4) {
    background-color: rgb(65, 62, 62);
    color: white;
    border: none;
}

.showHowToPlay {
    display: block;
}

@media (max-width: 700px) {
    .startBtn {
        width: 50%;
        height: 50px;
        left: 25%;
    }

    .myTimer {
        right: 70%;
        top: 90px;
    }

    .myTimer p {
        font-size: 24px;
    }

    .theHint {
        position: relative;
        text-align: center;
        margin-top: 100px;
        left: 0;
    }
}