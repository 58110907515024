.secDiv {
    color: white;
    display: flex;
    position: absolute;
    right: 80px;
    top: 15px;
    font-size: 24px
}

.hangBigDiv {
    width: 100%;
    height: 100vh;
    background-color: aliceblue;
    position: fixed;
    top: 55px;
    left: 0px;
    opacity: 0;
}

.hangBtn {
    width: 20%;
    height: 10%;
    position: fixed;
    top: 50%;
    left: 40%;
    cursor: pointer;
    border-radius: 12px;
    background-color: black;
    color: white;
    font-weight: 600;
}

.hangBtn:hover {
    background-color: purple;
    color: white;
}

.homeBtn {
    font-size: 20px;
    padding: 20px;
    color: white;
    cursor: pointer;
    position: fixed;
    z-index: 2;
}

.howToPlayHang {
    position: absolute;
    width: 40%;
    top: 10%;
    left: 30%;
    height: auto;
    background-color: white;
    padding: 20px;
    padding-bottom: 40px;
    border-radius: 16px;
    display: none;
}

.howToPlayHang>h1 {
    font-weight: 900;
    font-size: 30px;
}

.howToPlayHang>h4 {
    font-size: 20px;
}

.howToPlayHangImg {
    margin-left: 96%;
    margin-top: 10px;
    cursor: pointer;
}

.showHowToPlay {
    display: block;
}

@media (max-width: 700px) {
    .hangBtn {
        width: 50%;
        left: 25%;
    }

    .hangDrawDiv {
        margin-top: 100px;
    }
}