.theMain {
    padding: 30px;
}

.mainFirst {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    row-gap: 30px;
}

.mainFirst>p {
    background: #3d1152;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 44px;
    font-weight: 600;
    color: white;
    cursor: pointer;
}

.mainFirst>h4 {
    font-weight: 600;
    margin-left: 200px;
}

.mainFirst>div {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.mainDel {
    background: #F43F5E;
    border: 1px solid #F43F5E;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 44px;
    font-weight: 600;
    color: white;
    cursor: pointer;
}

.mainEdit {
    background: #2E7DD7;
    border: 1px solid #2E7DD7;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    height: 44px;
    font-weight: 600;
    color: white;
    cursor: pointer;
}

.vers {
    margin-top: 50px;
    font-weight: 600;
}

.mainSecond {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
    width: 100%;
}

.mainSecond>div {
    width: 31%;
    height: 150px;
    box-shadow: 2px 2px 4px #3d1152;
    border-radius: 12px;
    margin-top: 20px;
    padding: 10px 20px;
}

.mainVerse {
    font-weight: 600;
    text-transform: capitalize;
}

.verseContentX>p {
    line-height: 1.5rem;
    max-height: 3rem;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
}

.mainSecond>div span {
    color: #2E7DD7;
    cursor: pointer;
}

.mainThird {
    margin-top: 50px;
}

.mainThird>h3 {
    font-weight: 600;
}

.chapterx {
    width: 35%;
    height: auto;
    max-height: 550px;
    position: fixed;
    border-radius: 12px;
    box-shadow: 1px 1px 6px black;
    background-color: #fdfdff;
    top: 40px;
    left: 32.5%;
    padding: 30px 20px;
    display: none;
    overflow-y: scroll;
    overflow-x: hidden;
}

.chapterx>div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.chapterx>div>h5 {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}

.chapterx>div>img {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.chapterx>p {
    margin-top: 20px;
    line-height: 30px;
    font-weight: 500;
}

.delDivX {
    width: 35%;
    height: 230px;
    position: fixed;
    border-radius: 12px;
    box-shadow: 1px 1px 6px black;
    background-color: #fdfdff;
    top: 30%;
    left: 32.5%;
    padding: 30px 20px;
    display: none;
}

.showBlock {
    display: block;
}

@media screen and (max-width: 800px) {
    .mainSecond>div {
        width: 100%;
    }

    .mainSecond {
        gap: 20px;
    }

    .chapterx {
        width: 90%;
        left: 5%;
        top: 100px;
        max-height: 700px;
    }

    .delDivX {
        width: 90%;
        left: 5%;
        height: 260px;
    }

    .mainFirst>h4 {
        margin-left: 0px;
    }

    .mainDel,
    .mainEdit {
        width: 100%;
    }

    .mainFirst>div {
        width: 100%;
    }

}