.correctHebrewImg {
    width: 200px;
    height: 200px;
    border: 2px dotted gray;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.correctHebrewImg>img {
    max-width: 100%;
    max-height: 100%;
}