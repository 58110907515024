.innerSword>h4 {
    font-size: 18px;
}

.swordMain>div {
    padding: 20px 20px 5px 20px;
}

.swordSearch>p {
    width: 25%;
    margin-left: 10px;
}

.showDelModal {
    display: block;
}