:root {
    --primary: #FFCE00;
    --secondary: #FE4880;
    --dark: #212121;
    --light: #F3F3F3;
}

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: montserrat, sans-serif;
    width: 100%;
    min-height: 100vh;
}

.mainCard {
    margin: 30px auto 0;
    width: 300px;
    height: 400px;
    perspective: 1000px;
}

.card__inner {
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
}

.card__inner.is-flipped {
    transform: rotateY(180deg);
}


.card__face {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
}

.card__face--front {
    background-image: linear-gradient(to bottom right, var(--primary), var(--secondary));
    display: flex;
    align-items: center;
    justify-content: center;
}

.card__face--front h2 {
    color: #FFF;
    font-size: 32px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.card__face--back {
    background-color: var(--light);
    transform: rotateY(180deg);
}

.card__content {
    width: 100%;
    height: 100%;
}

.card__header {
    position: relative;
    padding: 20px;
    margin-left: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 220px;
    border-top-left-radius: 200px;
    border-bottom-left-radius: 200px;
}

.card__header:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom left, var(--primary) 10%, var(--secondary) 115%);
    z-index: -1;
    border-top-left-radius: 200px;
    border-bottom-left-radius: 200px;
}

.pp {
    display: block;
    width: 200px;
    height: 100%;
    border-radius: 50%;
    background-color: #FFF;
    border: 5px solid #FFF;
    object-fit: cover;
}

.card__header h2 {
    color: #FFF;
    font-size: 32px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
}

.card__body {
    padding: 30px;
    text-align: center;
}

.card__body h3 {
    color: var(--dark);
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.card__body p {
    color: var(--dark);
    font-size: 18px;
    line-height: 1.4;
}

.hebrewMainDiv {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 40px;
}

.hebrewFirst,
.hebrewLast {
    display: flex;
    flex-wrap: wrap;
    column-gap: 60px;
    align-items: center;
    max-width: 400px;
}

.hebrewLast {
    justify-content: flex-end;
}

.hebrewCard {
    width: 200px;
    height: 280px;
    margin-top: 20px;
    cursor: pointer;
}

.hebrewTimer,
.hebrewScore,
.mobileScore {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    color: white;
    flex-direction: column;
}

.mobileScore {
    display: none;
}

.hebrewCard>img {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    border: 1px solid purple;
    padding: 10px;
}

.congratHeb {
    margin-top: -230px;
}

@media (max-width: 700px) {

    .hebrewMainDiv {
        flex-wrap: wrap;
    }

    .congratHeb {
        margin-top: -35%;
    }

    .hebrewFirst,
    .hebrewLast {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 0px;
    }

    .hebrewScore {
        display: none;
    }

    .hebrewTimer,
    .mobileScore {
        width: 80px;
        height: 80px;
        font-size: 16px;
    }

    .mobileScore {
        display: flex;
    }

    .hebrewCard {
        width: 100px;
        height: 140px;
    }

    .mainCard {
        height: 320px;
        width: 230px;
    }

    .card__header {
        height: 180px;
    }

    .pp {
        width: 150px;
    }
}