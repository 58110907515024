.resultDiv {
    width: 30%;
    height: 600px;
    background-color: white;
    position: fixed;
    top: 10%;
    left: 35%;
    border-radius: 8px;
    text-align: center;
    display: none;
    padding: 10px;
}

.cancel {
    margin-left: 90%;
    margin-top: 4%;
    cursor: pointer;
}

.score {
    font-weight: 700;
    font-style: italic;
}

.nextX {
    width: 30%;
    height: 50px;
    margin-top: 20px;
    border: 2px solid #3d1152;
    outline: none;
    border-radius: 24px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
}

.nextX:hover {
    background-color: #3d1152;
    color: white;
}

.nextX:hover .arrow {
    filter: invert(100%);
}

.authDiv {
    border-top: 1px solid #3d1152;
    margin-top: 30px;
    padding: 20px;
}

.authDiv p {
    font-family: 'Switzer';
    font-style: normal;
}

.authDiv button {
    width: 90%;
    height: 50px;
    border: 2px solid #3d1152;
    outline: none;
    border-radius: 24px;
    font-weight: 600;
}

.authDiv button:hover {
    background-color: #3d1152;
    color: white;
}

@media (max-width: 700px) {
    .resultDiv {
        width: 90%;
        left: 5%;
    }

    .resultDiv>p {
        font-size: 14px;
    }

    .authDiv p {
        font-size: 14px
    }
}