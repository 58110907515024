.mainNames {
    padding: 30px;
    background-color: #3d1152;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.mainNames>form {
    width: 50%;
    min-height: 80vh;
    background-color: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
}

.mainNames>form>h4 {
    font-weight: 600;
    color: #3d1152;
}

.mainNames>form>div {
    width: 100%;
}

.mainNames>form>div>input {
    width: 100%;
    height: 40px;
    border: 1px solid #b9bdc5;
    border-radius: 6px;
    outline: none;
    padding-left: 20px;
    color: #5c5f64;
}

.mainNames>form>button {
    width: 100%;
    height: 40px;
    outline: none;
    background-color: #3d1152;
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: 600;
}

.mainNames>form>button:hover {
    background-color: white;
    color: #3d1152;
    border: 1px solid #3d1152;
}

.tribeMain {
    border: 1px solid #3d1152;
    width: 100%;
    height: 50px;
    border-radius: 8px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    font-size: 20px;
    cursor: pointer;
    font-family: 'Switzer';
    font-style: normal;
}

.mainNames label {
    margin-top: 20px;
    margin-bottom: 5px;
}

.homeBtnNames {
    font-size: 20px;
    color: white;
    cursor: pointer;
    position: fixed;
    z-index: 2;
    left: 30px;
}

.showName {
    display: block;
}

@media screen and (max-width: 800px) {
    .mainNames>form {
        width: 100%;
    }
}